(function () {
	$(window).on('included', function() {

		//スマホのみスライダー
		if($('.u-hidden-gtTAB').css('display') !== 'none') {
			let els = document.querySelectorAll('.js-simplebar');
			[].forEach.call(els, function(el) {
				new SimpleBar(el, {
					autoHide: false,
					direction: 'rtl'
				});
			})
		}
	});
})();
